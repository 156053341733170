import React, { useRef, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { animate, quadEaseInOut } from "@helper/animate";
import Layout from "@components/Layout";
import css from '@cssmodules/klien.module.scss';
import ArrowLeft from '@assets/images/icon/arrow-circle-left-black.svg'

import { ourClient } from "@utils/static-data";

const KlienKami = () => {
  const scrollRef = useRef(null);
  const { desktopBG, mobileBG } = useStaticQuery(graphql`
    query KlienKami {
      desktopBG: file(
        relativePath: { eq: "bg/desktop/bg-hero-klien-kami.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2024, maxHeight: 1047, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobileBG: file(relativePath: { eq: "bg/mobile/bg-hero-klien-kami.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 751, maxHeight: 1334, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const bg = [
    mobileBG.childImageSharp.fluid,
    {
      ...desktopBG.childImageSharp.fluid,
      media: "(min-width: 992px)",
    },
  ];

  const [activeLetter, setActiveLetter] = useState("a");

  const onLetterClick = (e, letter) => {
    e.preventDefault();
    setActiveLetter(letter);
  };

  const onButtonScrollClick = (e, direction) => {
    e.preventDefault();

    const current = scrollRef.current.scrollTop;
    const scroll = direction === "up" ? -100 : 100;

    animate({
      duration: 300,
      timing: quadEaseInOut,
      draw: function (progress) {
        scrollRef.current.scrollTop =
          current + (progress * scroll);
      },
    });
  };

  const abjadButton = () => {
    return (
      <div className={css.buttonWrap}>
        {Object.keys(ourClient).map((letter, _) => (
          <button
            onClick={e => onLetterClick(e, letter)}
            className={`${css.letter} ${
              letter === activeLetter ? css.active : ""
            }`}
          >
            {letter}
          </button>
        ))}
      </div>
    );
  };

  const clientRender = () => {
    let activeClient = [...ourClient[activeLetter]];
    let half = Math.ceil(activeClient.length / 2);

    let left = activeClient.splice(0, half);
    let right = activeClient.splice(-half);

    return (
      <div className={css.clientWrapper}>
        <div className={css.clientRow} ref={scrollRef}>
          <div className={css.clientCol}>
            {left.map((val, _) => (
              <p>{val}</p>
            ))}
          </div>
          <div className={css.clientCol}>
            {right.map((val, _) => (
              <p>{val}</p>
            ))}
          </div>
        </div>
        <div className={css.buttonNav}>
          <button className={css.up} onClick={e => onButtonScrollClick(e, "up")}><img src={ArrowLeft} /></button>
          <button className={css.down} onClick={e => onButtonScrollClick(e, "bottom")}><img src={ArrowLeft} /></button>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      {/* HERO HEADER */}
      <header className={css.header}>
        <div className={css.img}>
          <Img
            fluid={bg}
            alt="klien-kami"
            style={{ width: 100 + "%", height: 100 + "%" }}
          />
        </div>
        <div className={css.titleWrapper}>
          <h1>
            <span>Dibawah ini adalah daftar klien</span>
            <span>yang telah order di kami</span>
            <span>sesuai huruf abjad</span>
          </h1>

          <div className={css.border} />
        </div>
      </header>

      {/* CLIENT SECTION */}
      <section className={css.client}>
        {/* BUTTON ABJAD */}
        {abjadButton()}

        {/* CLIENT WRAPPER */}
        {clientRender()}
      </section>
    </Layout>
  );
};

export default KlienKami;
